import { Wrapper } from '@components/ui/wrapper/wrapper.style';
import Link from 'next/link';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as S from './footer.style';
import { Icon } from '@components/ui/icons/icon';
import { LocationButton } from '@components/layout/buttons/location-button/location-button';
import { FooterProps } from './types';
import { NoSSR } from '@components/NoSSR';
import { useStore } from '@stores/root';
import { FooterAnchor } from './Anchor/footer-anchor';
import { observer } from 'mobx-react-lite';
import { About } from '@components/main/blocks/about/about';
import { CommonBlockWithTitle } from '@components/blocks/common-block-with-title';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import { getLogoFontSize } from '../../../utils/logo';
import { WH } from '@components/WH';

const year = new Date().getFullYear();

export const Footer = observer((props: FooterProps) => {
  const [projectNameWidth, setProjectNameWidth] = useState(0);
  const projectNameRef = useRef<HTMLDivElement>();
  const router = useRouter();
  const { project, ui, geo } = useStore();
  const domain = `${project.domain[0].toUpperCase()}${project.domain.slice(1)}`;
  const s = project.domain.split('.');
  const secondDomain = `${s[s.length - 2]}.${s[s.length - 1]}`;
  const updatedAt = new Date(props?.updatedAt ?? project?.updatedAt);

  const [name, address, index, phone] = geo.current?.address ?? [];

  const hrefPhone = useMemo(
    () => phone?.replace(/\D+/g, '') ?? project.phone.replace(/\D+/g, ''),
    []
  );

  useEffect(() => {
    if (projectNameRef.current) {
      setProjectNameWidth(projectNameRef.current.clientWidth);
    }
  }, []);

  const popularCategoriesNames = props.categories
    .slice(0, 4)
    ?.map((category) => category.name)
    .join(', ');

  const getLargeClass = (size: number) => {
    if (size > 11) return 'large extraLarge';
    if (size < 5) return '';
    return 'large';
  };

  return (
    <S.StyledFooter noMargin={props.noMargin}>
      {!props.noMargin && !props.hideAboutBlock && (
        <CommonBlockWithTitle>
          <WH>
            <About text={props.aboutBlockText} showTitle />
          </WH>
        </CommonBlockWithTitle>
      )}

      <S.FooterTop>
        <Wrapper>
          <S.FooterTopContent className={getLargeClass(props.projectName.length)}>
            <S.FooterTopContentFlex>
              <S.LogoBlock>
                <S.Logo>{project.logoLetters}</S.Logo>
                <S.LogoMarketNameBlock>
                  {props.projectName && (
                    <S.LogoMarketName
                      ref={projectNameRef}
                      fontSize={getLogoFontSize(props.projectName, ui.viewport).value}
                      isUpperCase={getLogoFontSize(props.projectName, ui.viewport).isUpperCase}
                    >
                      {props.projectName}
                    </S.LogoMarketName>
                  )}
                  {props.postfix && <S.LogoMarketSubname>{props.postfix}</S.LogoMarketSubname>}
                </S.LogoMarketNameBlock>
                {!(ui.viewport <= 480 && props.projectName.length > 10) && (
                  <>
                    <S.LogoDivider />
                    <S.LogoMarketDescription>
                      специализированный маркетплейс
                    </S.LogoMarketDescription>
                  </>
                )}
              </S.LogoBlock>

              <S.FooterNav>
                <Link legacyBehavior prefetch={false} href="/about">
                  О сервисе
                </Link>
                <Link legacyBehavior prefetch={false} href="/contacts">
                  Контакты
                </Link>
                {/* <Link legacyBehavior prefetch={false} href="/info/sellers">
                  Продавцам
                </Link> */}
                <Link legacyBehavior prefetch={false} href="/info/guide">
                  Как это работает
                </Link>
                <Link legacyBehavior prefetch={false} href="/info/customers">
                  Покупателям
                </Link>
                <Link legacyBehavior prefetch={false} href="/info/vacancies">
                  Вакансии
                </Link>
                <Link legacyBehavior prefetch={false} href="/info/delivery">
                  Оплата и доставка
                </Link>
                <Link legacyBehavior prefetch={false} href="/feedback">
                  Обратная связь
                </Link>
                <Link legacyBehavior prefetch={false} href="/info/garanties">
                  Гарантия и возвраты
                </Link>
              </S.FooterNav>

              <S.FooterSocials>
                <S.FooterBlock>
                  <S.FooterBlockTitle>Присоединяйтесь к нам</S.FooterBlockTitle>
                  <S.FooterBlockContent>
                    <S.FooterBlockSocialsContent>
                      <S.FooterSocial className="vk">
                        <Icon icon="vk" />
                      </S.FooterSocial>

                      <S.FooterSocial className="facebook">
                        <Icon icon="facebook" />
                      </S.FooterSocial>

                      <S.FooterSocial className="twitter">
                        <Icon icon="twitter" />
                      </S.FooterSocial>

                      <S.FooterSocial className="ok">
                        <Icon icon="ok" />
                      </S.FooterSocial>

                      <S.FooterSocial className="youtube">
                        <Icon icon="youtube" />
                      </S.FooterSocial>

                      <S.FooterSocial className="instagram">
                        <Icon icon="instagram" />
                      </S.FooterSocial>
                    </S.FooterBlockSocialsContent>
                  </S.FooterBlockContent>
                </S.FooterBlock>
              </S.FooterSocials>

              <S.FooterLocation>
                <S.FooterBlock>
                  <S.FooterBlockTitle>Ваш город</S.FooterBlockTitle>
                  <S.FooterBlockContent>
                    <LocationButton hidePopup />
                  </S.FooterBlockContent>
                </S.FooterBlock>
              </S.FooterLocation>
            </S.FooterTopContentFlex>

            <S.FooterTopSEO>
              <noindex>
                {domain} — каталог описаний и цен на {popularCategoriesNames}. Наша задача — помочь
                подобрать и купить {props.currentCategory ?? 'товары'} по лучшей цене в
                интернет-магазинах - от бюджетных до премиум. В каталоге можно найти всю необходимую
                для выбора информацию — сравнение {props.currentCategory ?? 'товаров'}, подбор
                моделей по параметрам, подробные описания, поиск товара по названию, отзывы
                пользователей, фотогалереи товаров, глоссарий терминов, обзоры, инструкции, рейтинг
                товаров, рекомендации экспертов, каталог брендов и многое другое. Перепечатка любых
                материалов разрешена только с письменного согласия редакции.
              </noindex>
            </S.FooterTopSEO>
          </S.FooterTopContent>
        </Wrapper>
      </S.FooterTop>

      <NoSSR>
        <FooterAnchor viewport={ui.viewport} />
      </NoSSR>

      <S.FooterBottom>
        <Wrapper>
          <S.FooterBottomContent extendPadding={router.pathname.indexOf('/p') >= 0}>
            <S.FooterBottomLeft>
              <WH>
                <S.FooterBottomOffer>
                  Сайт {project.domain} не является интернет-магазином. <br />
                  Телефон для связи: <a href="tel:83455127501">
                    {' '}
                    8 (34551) 2-75-01
                  </a>. <br />
                  E-mail: <a href={`mailto:ishim@${secondDomain}`}>ishim@{secondDomain} </a>
                  . <br />
                  Информация размещенная на сайте, не является публичной офертой
                </S.FooterBottomOffer>
              </WH>
              <S.FooterBottomText className="responsive">
                Все права на бренд «{project.name}» принадлежат производителю
              </S.FooterBottomText>

              <S.FooterBottomLinks>
                <Link legacyBehavior prefetch={false} href="/info/agreement">
                  Пользовательское соглашение
                </Link>
                <Link legacyBehavior prefetch={false} href="/info/privacy">
                  Политика обработки персональных данных
                </Link>
              </S.FooterBottomLinks>

              <S.FooterBottomText>
                Все права на бренд «{project.name}» принадлежат производителю
              </S.FooterBottomText>
            </S.FooterBottomLeft>
            <S.FooterBottomRight>
              {project.updatedAt && (
                <S.FooterBottomUpdateDate>
                  Обновлено {dayjs(updatedAt).utcOffset(3).format('DD.MM.YYYY')} в{' '}
                  {dayjs(updatedAt).utcOffset(3).format('HH:mm')} по московскому времени.
                </S.FooterBottomUpdateDate>
              )}

              <S.FooterBootomCopyright>
                {/* <S.FooterBootomCopyrightText>
                  Вы можете взять этот сайт в аренду, по данному вопросу пишите в телеграм <a href="https://t.me/DanilaLidopad" style={{color: "rgb(229, 78, 141)"}}>@DanilaLidopad</a>
                </S.FooterBootomCopyrightText> */}
                {/* <S.FooterBootomCopyrightLogo>
                  <a href="https://lidopad.ru">
                    <span className="hidden">Лидопад</span>
                    <Icon icon="logo" />
                  </a>
                </S.FooterBootomCopyrightLogo> */}
              </S.FooterBootomCopyright>
            </S.FooterBottomRight>
          </S.FooterBottomContent>
        </Wrapper>
      </S.FooterBottom>
    </S.StyledFooter>
  );
});
